var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{staticStyle:{"height":"130vh !important!","zoom":"1.4"},attrs:{"id":"productEditSidebar","aria-labelledby":"sidebar-no-header-title","body-class":"bg-white","shadow":"","width":"635px","backdrop":true,"right":true,"z-index":"9998"},on:{"shown":_vm.shownSidebar,"hidden":_vm.hiddenSidebar},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var hide = ref.hide;
return [_c('div',[_c('div',{staticClass:"sidebar-header d-flex align-items-center"},[_c('span',{staticClass:"icon"},[_c('v-icon',{attrs:{"icon":['fac', 'edit']}})],1),_c('h4',{staticClass:"p-0 m-0",attrs:{"id":"sidebar-no-header-title"}},[_vm._v(" "+_vm._s(_vm.$t("myquotes.edit-quote"))+" ")])])])]}},{key:"default",fn:function(){return [(_vm.loading)?_c('div',{staticClass:"d-flex flex-column h-100 justify-content-center align-items-center"},[_c('loading',{staticClass:"my-auto",attrs:{"loading":_vm.loading}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"py-5 px-4"},[_c('validation-observer',{ref:"editItem",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var touched = ref.touched;
var invalid = ref.invalid;
var changed = ref.changed;
return [_c('div',{staticClass:"form-group mb-34"},[_c('div',{staticClass:"form-group mb-34"},[_c('validation-provider',{attrs:{"name":"Industry","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"form-group mb-5"},[_c('b-form-radio-group',{staticClass:"mr-3",attrs:{"options":_vm.statusOptions,"name":"active"},model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}})],1),_c('label',{attrs:{"for":"#"}},[_vm._v(" "+_vm._s(_vm.$t("myquotes.label-0"))+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('multi-select',{class:{
                    'is-invalid':
                      !_vm.getValidationState(validationContext) &&
                      validationContext.errors[0],
                  },attrs:{"name":"Industry","id":"industry","openDirection":'bottom',"searchable":false,"options":_vm.industrys},on:{"close":_vm.closeSelect},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(" "+_vm._s(props.option.name)+" ")])])]}},{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(" "+_vm._s(props.option.name)+" ")])])]}}],null,true),model:{value:(_vm.form.industry),callback:function ($$v) {_vm.$set(_vm.form, "industry", $$v)},expression:"form.industry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1)]),_c('validation-provider',{attrs:{"name":"Product Name","rules":{ itemName: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"form-group mb-34"},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("myquotes.label-1"))+" "),_c('small',{staticClass:"ml-2",staticStyle:{"color":"c5c5c5"}},[_vm._v(" "+_vm._s(_vm.$t("myquotes.label-desc")))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                  'is-invalid':
                      (
                          !_vm.getValidationState(validationContext) 
                          &&
                          validationContext.errors[0]
                      )
                },attrs:{"spellcheck":"true","type":"text","placeholder":"Type in the name of the product you are looking for"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(validationContext.errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")]):_vm._e()])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Product ID","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"form-group mb-34"},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("myquotes.label-2"))+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id),expression:"form.id"}],staticClass:"form-control",class:{
                  'is-invalid':
                    !_vm.getValidationState(validationContext) &&
                    validationContext.errors[0],
                },attrs:{"spellcheck":"true","type":"text","placeholder":"Type in the name of the product you are looking for"},domProps:{"value":(_vm.form.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "id", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Product Description","rules":{ itemDescription: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{staticClass:"form-group mb-34"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("myquotes.label-3")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.desc),expression:"form.desc"}],staticClass:"form-control",class:{
                  'is-invalid':
                      (
                          !_vm.getValidationState(validationContext) 
                          &&
                          validationContext.errors[0]
                      )
                },attrs:{"spellcheck":"true","type":"text","rows":"4"},domProps:{"value":(_vm.form.desc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "desc", $event.target.value)}}}),_c('div',{staticClass:"counter"},[(validationContext.errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")]):_vm._e(),_c('div',{staticClass:"number-back-counter"},[_vm._v(" "+_vm._s(_vm.counter)+" / 1200 ")])])])]}}],null,true)}),_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("myquotes.label-4"))+" "),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('div',{staticClass:"dropzone-content mb-34"},[_c('vue-dropzone',{ref:"myVueDropzone",attrs:{"id":"dropzone","options":_vm.dropzoneOptions,"useCustomSlot":true},on:{"vdropzone-removed-file":_vm.deleteImage,"vdropzone-files-added":_vm.fileUpload,"vdropzone-thumbnail":_vm.getThumbnail}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" "+_vm._s(_vm.$t("myquotes.addimg-title"))+" ")]),_c('small',{staticClass:"text-muted d-block"},[_vm._v(_vm._s(_vm.$t("myquotes.or")))]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.add"))+" ")]),_c('p',[_vm._v("JPG,PNG 5MB "+_vm._s(_vm.$t("myquotes.max")))])])]),_c('div',{staticClass:"img-preview-content mt-3 d-flex justify-content-between"},_vm._l((5),function(b,i){return _c('div',{key:i,staticClass:"item"},[(_vm.productImages[i])?_c('div',{staticClass:"clearBtn",on:{"click":function($event){$event.preventDefault();return _vm.deleteThumbnail(i, _vm.productImages[i].type)}}},[_vm._v(" X ")]):_vm._e(),_c('img',{attrs:{"src":!_vm.productImages[i]
                      ? _vm.noImage
                      : /^data/i.test(_vm.productImages[i].src)
                          ? _vm.productImages[i].src 
                          : _vm.CDN_IMAGE_PREFIX + _vm.productImages[i].src,"alt":""}})])}),0)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group mb-34"},[_c('validation-provider',{attrs:{"name":"units","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"#"}},[_vm._v(" "+_vm._s(_vm.$t("myquotes.label-5"))+" ")]),_c('multi-select',{class:{
                      'is-invalid':
                        !_vm.getValidationState(validationContext) &&
                        validationContext.errors[0],
                    },attrs:{"name":"units","id":"units","openDirection":'bottom',"searchable":false,"options":_vm.units},on:{"close":_vm.closeSelect},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(" "+_vm._s(props.option.name)+" ")])])]}},{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(" "+_vm._s(props.option.name)+" ")])])]}}],null,true),model:{value:(_vm.form.unit),callback:function ($$v) {_vm.$set(_vm.form, "unit", $$v)},expression:"form.unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"form-group row mb-34 d-flex justify-content-between"},[_c('div',{staticClass:"col"},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("myquotes.label-6"))+" ")]),_c('multi-select',{attrs:{"placeholder":'Select currency',"track-by":"name","id":"relatedIndustry","label":"name","clearOnSelect":false,"closeOnSelect":true,"allowEmpty":false,"showLabels":false,"options":_vm.currencies},model:{value:(_vm.selectedCurrency),callback:function ($$v) {_vm.selectedCurrency=$$v},expression:"selectedCurrency"}})],1),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":"Minimum Price","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("myquotes.label-7"))+" ")]),_c('currency-input',{staticClass:"form-control",class:{
                    'is-invalid':
                      !_vm.getValidationState(validationContext) &&
                      validationContext.errors[0],
                  },attrs:{"currency":_vm.selectedCurrency.code},model:{value:(_vm.form.min_price),callback:function ($$v) {_vm.$set(_vm.form, "min_price", $$v)},expression:"form.min_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":"Maximum Price","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("myquotes.label-8"))+" ")]),_c('currency-input',{staticClass:"form-control",class:{
                    'is-invalid':
                      !_vm.getValidationState(validationContext) &&
                      validationContext.errors[0],
                  },attrs:{"currency":_vm.selectedCurrency.code},model:{value:(_vm.form.max_price),callback:function ($$v) {_vm.$set(_vm.form, "max_price", $$v)},expression:"form.max_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group row mb-34 d-flex justify-content-between"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group mb-34 d-flex justify-content-between"},[_c('div',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"name":"Stock","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("myquotes.label-9"))+" "),_c('span',{staticStyle:{"color":"#989898"}},[_vm._v("( "+_vm._s(_vm.form.unit.shortname)+" )")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.stock),expression:"form.stock"}],staticClass:"form-control",class:{
                        'is-invalid':
                          !_vm.getValidationState(validationContext) &&
                          validationContext.errors[0],
                      },attrs:{"spellcheck":"true","type":"number"},domProps:{"value":(_vm.form.stock)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "stock", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group mb-34 d-flex justify-content-between"},[_c('div',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"name":"moq","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("myquotes.label-10"))+" "),_c('span',{staticStyle:{"color":"#989898"}},[_vm._v(" ( "+_vm._s(_vm.form.unit.shortname)+" )")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.moq),expression:"form.moq"}],staticClass:"form-control",class:{
                        'is-invalid':
                          !_vm.getValidationState(validationContext) &&
                          validationContext.errors[0],
                      },attrs:{"spellcheck":"true","type":"number"},domProps:{"value":(_vm.form.moq)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "moq", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1)])])])]}}],null,false,181895923)})],1):_vm._e()]},proxy:true},{key:"footer",fn:function(ref){
                      var hide = ref.hide;
return [_c('div',{staticClass:"d-flex bg-white shadow-lg justify-content-between align-items-center px-3 py-3"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return hide.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("buttons.exit")))]),_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":_vm.updateItem}},[_vm._v(" "+_vm._s(_vm.$t("buttons.save"))+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }