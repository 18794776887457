<template>
  <div class="container my-quotes-tabs zoom calc10">
    <div class="row">
      <div class="col-12">
        <h3 class="page-title">{{ $t('myquotes.my-quotes') }}</h3>
      </div>
    </div>
    <div class="row" v-if="alert.status">
      <div class="col-12">
        <div class="alert alert-danger">
          {{ alert.message }}
        </div>
      </div>
    </div>
    <loading :loading="loading" />
    <empty-item v-if="!loading && isEmpty" />

    <div v-if="!loading && !isEmpty" class="row no-gutters listing-badges ">
      <div class="col-12">
        <keep-alive>
          <b-tabs
            class="listings__tabs"
            v-model="tabIndex"
            content-class="mt-4 w-100"
          >
            <b-tab class="w-100">
              <template #title>
                <p class="">
                  {{ $t('myquotes.public') }}
                  <b-badge class="ml-20" variant="light">{{
                    countActive
                  }}</b-badge>
                </p>
              </template>
              <div v-if="!loading && list.length > 0" class="row no-gutters">
                <div class="col-12">
                  <b-row>
                    <b-col cols="12" class="request-headers-listing-tags">
                      <b-row class="table-columns">
                        <b-col cols="3">
                          <h6>{{ $t('myquotes.title-desc') }}</h6>
                        </b-col>
                        <b-col cols="2" class="pl-2">
                          <h6>{{ $t('myquotes.desc') }}</h6>
                        </b-col>
                        <b-col cols="2" class="pl-1">
                          <h6>
                            {{ $t('myquotes.ppu') }}
                            <!-- <img
                              src="/img/down-arrow.svg"
                              class="img-fluid ml-2"
                              alt=""
                            /> -->
                          </h6>
                        </b-col>
                        <b-col cols="2" class="pl-1">
                          <h6>
                            {{ $t('myquotes.moq') }}
                            <!-- <img
                              src="/img/down-arrow.svg"
                              class="img-fluid ml-2"
                              alt=""
                            /> -->
                          </h6>
                        </b-col>
                        <b-col cols="1" class="pl-0">
                          <h6>
                            {{ $t('myquotes.capacity') }}
                            <!-- <img
                              src="/img/down-arrow.svg"
                              class="img-fluid ml-2"
                              alt=""
                            /> -->
                          </h6>
                        </b-col>
                        <b-col cols="2"> </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-else class="text-center quotes-h3">
                <div class="dont-have-active-passive">
                  <div class="row">
                    <div class="col-md-12">
                      <h3>{{ $t('myquotes.noquotes-p') }}</h3>
                    </div>
                    <div class="col-md-12 text-right">
                      <img
                        src="/img/dont-have.svg"
                        class="img-fluid"
                        style="margin-top:250px;"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="list-content">
                    <div
                      class="accordion"
                      role="tablist"
                      id="listViewAccordion"
                      style="margin-top: -30px"
                    >
                      <b-card
                        no-body
                        class="mb-1 list-card"
                        v-for="(item, index) in list"
                        :key="item.id"
                        :style="item.disabled === true ? {opacity: 0.4, filter: 'alpha(opacity=40)'}: {}"
                      >
                        <b-card-header
                          header-tag="header"
                          class="p-1 list-view-headers"
                          role="tab"
                        >
                          <b-button
                            block
                            variant="info"
                            class="list-view-button"
                          >
                            <b-row align-v="center" id="member-table-list">
                              <b-col cols="3">
                                <b-row>
                                  <b-col cols="2">
                                    <img
                                      :src="
                                        CDN_IMAGE_PREFIX +
                                          item.images[0] +
                                          '?size=50xauto'
                                      "
                                      class="mr-3"
                                      alt=""
                                      width="50"
                                      style="width: 50px; height: 50px; border-radius:6px"
                                    />
                                  </b-col>
                                  <b-col cols="10" class="pl-5">
                                    <h4>
                                      {{ getDefault(item.contents).name }}
                                    </h4>
                                    <h6 class="mb-0">
                                      {{ $t('myquotes.id') }}:
                                      {{ item.product_id }}
                                    </h6>
                                  </b-col>
                                </b-row>
                              </b-col>
                              <b-col cols="2">
                                <nl2br
                                  tag="h6"
                                  :text="getDefault(item.contents).description"
                                />
                              </b-col>
                              <b-col cols="2">
                                <h6 class="ctr-black-color">
                                  {{ item.currency.symbol }}
                                  {{ item.min_price }} -
                                  {{ item.currency.symbol }}
                                  {{ item.max_price }}
                                  <span class="member-color-grey"
                                    >/ {{ item.unit.name }}</span
                                  >
                                </h6>
                              </b-col>
                              <b-col cols="2">
                                <h6 class="ctr-black-color">
                                  {{ numberFixer(item.moq) }}
                                  <span class="member-color-grey">
                                    {{ item.unit.name }}</span
                                  >
                                </h6>
                              </b-col>
                              <b-col cols="1">
                                <h6 class="ctr-black-color">
                                  {{ numberFixer(item.stock) }}
                                  <span class="member-color-grey">
                                    {{ item.unit.shortname }}</span
                                  >
                                  <span class="member-color-grey" style="line-height:20px; font-size:14px;">
                                    ({{ $t('buttons.monthly') }}) </span
                                  >
                                </h6>
                              </b-col>
                              <b-col cols="2" class="text-right">
                                <a
                                  href="javascript:void(0)"
                                  style="margin-right: 25px"
                                  v-b-toggle="'collapse' + item.id"
                                >
                                  <img src="/img/member-accord.svg" alt="" />
                                </a>

                                <a
                                  @click.prevent="openEditItem(index)"
                                  class="ml-0"
                                  href="#"
                                  style="color: #1976D2;"
                                >
                                  <v-icon
                                    :icon="['fac', 'edit']"
                                    class="mr-3"
                                  />
                                </a>
                              </b-col>
                            </b-row>
                          </b-button>
                        </b-card-header>
                        <b-collapse
                          :id="'collapse' + item.id"
                          :visible="false"
                          accordion="my-accordion"
                          role="tabpanel"
                          class="collapse-requests"
                        >
                          <b-card-body>
                            <b-card-text>
                              <b-row>
                                <b-col cols="3">
                                  <b-carousel
                                    id="carousel-no-animation"
                                    style="text-shadow: 0px 0px 2px #000; border-radius:6px !important;"
                                    no-animation
                                    indicators
                                    img-width="1024"
                                    img-height="480"
                                  >
                                    <b-carousel-slide
                                      v-for="img in item.images"
                                      :key="img.id"
                                      :img-src="
                                        CDN_IMAGE_PREFIX +
                                          img +
                                          '?size=250xauto'
                                      "
                                    ></b-carousel-slide>
                                  </b-carousel>
                                </b-col>
                                <b-col cols="9">
                                  <div class="member-view-close-button">
                                    <!-- <a href="#" v-b-toggle:accordion-1>{{
                                      $t('buttons.close')
                                    }}</a> -->
                                  </div>
                                  <b-row>
                                    <b-col cols="10" class="mb-1">
                                      <h1>
                                        {{ getDefault(item.contents).name }}
                                      </h1>
                                      <p class="mb-0">
                                        {{ $t('myquotes.id') }}:
                                        {{ item.product_id }}
                                      </p>
                                      <nl2br
                                        tag="p"
                                        className="mb-35"
                                        :text="
                                          getDefault(item.contents).description
                                        "
                                      />
                                    </b-col>
                                  </b-row>

                                  <b-row>
                                    <b-col cols="12">
                                      <b-row>
                                        <b-col cols="5">
                                          <p class="quotations-column-content">
                                            {{ $t('myquotes.ppu') }}
                                            <span
                                              > {{ item.currency.symbol }}
                                              {{ item.min_price }} -
                                              {{ item.currency.symbol }}
                                              {{ item.max_price }}</span
                                            >
                                            <span class="ton-color"
                                              >/ {{ item.unit.shortname }}</span
                                            >
                                          </p>
                                        </b-col>

                                        <b-col cols="3">
                                          <p class="quotations-column-content">
                                            {{ $t('myquotes.moq') }}
                                            <span>{{ numberFixer(item.moq)}} </span>
                                            <span class="ton-color">
                                              {{ item.unit.shortname }}</span
                                            >
                                          </p>
                                        </b-col>

                                        <b-col cols="4">
                                          <p class="quotations-column-content">
                                            {{ $t('myquotes.capacity') }}
                                           <span> {{ numberFixer(item.stock) }}      </span>
                                            <span class="ton-color">
                                              {{ item.unit.shortname }}</span>
                                            
                                          </p>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-card-text>
                            <!--<b-card-text>{{ text }}</b-card-text> -->
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>

                    <!--  <b-overlay
                      :show="selectIndex.includes(index)"
                      rounded
                      opacity="0.9"
                      v-for="(item, index) in list"
                      :key="index"
                      spinner-small
                      spinner-variant="gray"
                      class="w-100"
                      z-index="9999"
                    >
                      <div class="item d-flex align-items-center">
                        <div class="col pn d-flex align-items-center">
                          <div>
                            <img
                              :src="
                                !isValidURL(item.images[0])
                                  ? item.images[0]
                                  : item.images[0] + '?size=50xauto'
                              "
                              alt=""
                            />
                          </div>
                          <div>
                            <h3 class="product-name onepar">
                              {{ getDefault(item.contents).name }}
                            </h3>
                            <span class="product-id onepar"
                              >Product ID: {{ item.product_id }}</span
                            >
                          </div>
                        </div>
                        <div
                          style="min-width: 310px; max-width: 310px"
                          class="col con"
                        >
                          <span class="product-desc"
                            >{{ getDefault(item.contents).description }}
                          </span>
                        </div>
                        <div
                          style="min-width: 154px; max-width: 154px"
                          class="col un"
                        >
                          <span class="product-unit">{{ item.unit.shortname }}</span>
                        </div>
                        <div
                          style="min-width: 254px; max-width: 254px"
                          class="col"
                        >
                          <span class="product-price"
                            >{{ item.currency.symbol }} {{ item.min_price }} -
                            {{ item.max_price }}</span
                          >
                        </div>
                        <div
                          style="min-width: 211px; max-width: 211px"
                          class="col d-flex justify-content-between"
                        >
                          <span class="product-price">{{ item.stock }}</span>
                          <b-dropdown
                            right
                            :ref="'dropdown' + index"
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                          >
                            <template #button-content class="p-0">
                              <v-icon :icon="['fas', 'ellipsis-v']"></v-icon>
                            </template>
                            <div class="py-2 px-3">
                              <b-form-checkbox
                                @change="itemStatusChange(index)"
                                v-model="item.active"
                                name="check-button"
                                switch
                              >
                                Active
                              </b-form-checkbox>
                            </div>
                            <b-dropdown-item
                              @click.prevent="openEditItem(index)"
                              class="ml-0"
                              href="#"
                              ><v-icon
                                :icon="['fac', 'edit']"
                                class="mr-3"
                              />Edit</b-dropdown-item
                            >
                          </b-dropdown>
                        </div>
                      </div>
                    </b-overlay> -->
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab class="w-100">
              <template #title>
                <p class="">
                  {{ $t('myquotes.passive') }}
                  <b-badge class="ml-20" variant="light">{{
                    countPassive
                  }}</b-badge>
                </p>
              </template>
              <div v-if="!loading && list.length > 0" class="row no-gutters ">
                <div class="col-12">
                  <b-row>
                    <b-col cols="12" class="request-headers-listing-tags">
                      <b-row class="table-columns">
                        <b-col cols="3">
                          <h6>{{ $t('myquotes.title-desc') }}</h6>
                        </b-col>
                        <b-col cols="2">
                          <h6>{{ $t('myquotes.desc') }}</h6>
                        </b-col>
                        <b-col cols="2" style="margin-left:-12px;">
                          <h6>
                            {{ $t('myquotes.ppu') }}
                            <!-- <img
                              src="/img/down-arrow.svg"
                              class="img-fluid ml-2"
                              alt=""
                            /> -->
                          </h6>
                        </b-col>
                        <b-col cols="2">
                          <h6>
                            {{ $t('myquotes.moq') }}
                            <!-- <img
                              src="/img/down-arrow.svg"
                              class="img-fluid ml-2"
                              alt=""
                            /> -->
                          </h6>
                        </b-col>
                        <b-col cols="2" style="margin-left:-5px;">
                          <h6>
                            {{ $t('myquotes.capacity') }}
                            <!-- <img
                              src="/img/down-arrow.svg"
                              class="img-fluid ml-2"
                              alt=""
                            /> -->
                          </h6>
                        </b-col>
                        <b-col cols="1"> </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-else class="text-center quotes-h3">
                <div class="dont-have-active-passive">
                  <div class="row">
                    <div class="col-md-12">
                      <h6>{{ $t('myquotes.noquotes-pp') }}</h6>
                    </div>
                    <div class="col-md-12 text-right">
                      <img
                        src="/img/dont-have.svg"
                        class="img-fluid"
                        style="margin-top:250px;"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="list-content">
                    <div
                      class="accordion"
                      role="tablist"
                      id="listViewAccordion"
                      style="margin-top: -30px"
                    >
                      <b-card
                        no-body
                        class="mb-1 list-card"
                        v-for="(item, index) in list"
                        :key="item.id"
                        :style="item.disabled === true ? {opacity: 0.4, filter: 'alpha(opacity=40)'}: {}"
                      >
                        <b-card-header
                          header-tag="header"
                          class="p-1 list-view-headers"
                          role="tab"
                        >
                          <b-button
                            block
                            variant="info"
                            class="list-view-button"
                          >
                            <b-row align-v="center" id="member-table-list">
                              <b-col cols="3">
                                <b-row>
                                  <b-col cols="2">
                                    <img
                                      :src="
                                        CDN_IMAGE_PREFIX +
                                          item.images[0] +
                                          '?size=50xauto'
                                      "
                                      class="mr-3"
                                      alt=""
                                      style="width: 50px; height: 50px"
                                    />
                                  </b-col>
                                  <b-col cols="10" class="pl-5">
                                    <h4>
                                      {{ getDefault(item.contents).name }}
                                    </h4>
                                    <h6 class="mb-0">
                                      {{ $t('myquotes.id') }}:
                                      {{ item.product_id }}
                                    </h6>
                                  </b-col>
                                </b-row>
                              </b-col>
                              <b-col cols="2">
                                <nl2br
                                  tag="h6"
                                  :text="getDefault(item.contents).description"
                                />
                              </b-col>
                              <b-col cols="2">
                                <h6 class="ctr-black-color">
                                  {{ item.currency.symbol }}
                                  {{ item.min_price }} -
                                  {{ item.max_price }}
                                  <span class="member-color-grey"
                                    >/ {{ item.unit.shortname }}</span
                                  >
                                </h6>
                              </b-col>
                              <b-col cols="2">
                                <h6 class="ctr-black-color">
                                  {{numberFixer(item.moq)}}
                                  <span class="member-color-grey"
                                    >/ {{ item.unit.name }}</span
                                  >
                                </h6>
                              </b-col>
                              <b-col cols="2">
                                <h6 class="ctr-black-color">
                                  {{numberFixer(item.stock) }}
                                  <span class="member-color-grey">{{
                                    item.unit.shortname
                                  }}</span>

                                  <span class="member-color-grey">
                                    {{ $t('buttons.monthly') }}</span
                                  >
                                </h6>
                              </b-col>
                              <b-col cols="1" class="text-right">
                                <a
                                  href="javascript:void(0)"
                                  style="margin-right: 15px"
                                  v-b-toggle="'collapse' + item.id"
                                >
                                  <img src="/img/member-accord.svg" alt="" />
                                </a>

                                <a
                                  @click.prevent="openEditItem(index)"
                                  class="ml-0"
                                  href="#"
                                  style="color: #1976D2;"
                                  ><v-icon :icon="['fac', 'edit']" class="mr-3"
                                /></a>
                              </b-col>
                            </b-row>
                          </b-button>
                        </b-card-header>
                        <b-collapse
                          :id="'collapse' + item.id"
                          :visible="false"
                          accordion="my-accordion"
                          role="tabpanel"
                          class="collapse-requests"
                        >
                          <b-card-body>
                            <b-card-text>
                              <b-row>
                                <b-col cols="3">
                                  <b-carousel
                                    id="carousel-no-animation"
                                    style="text-shadow: 0px 0px 2px #000; border-radius:6px !important;"
                                    no-animation
                                    indicators
                                    img-width="1024"
                                    img-height="480"
                                  >
                                    <b-carousel-slide
                                      v-for="(image, index) in item.images"
                                      :key="index"
                                      :img-src="CDN_IMAGE_PREFIX + image"
                                    >
                                    </b-carousel-slide>
                                  </b-carousel>
                                </b-col>
                                <b-col cols="9">
                                  <!-- <div class="member-view-close-button">
                                    <a href=""> {{ $t('buttons.close') }}</a>
                                  </div> -->
                                  <b-row>
                                    <b-col cols="10" class="mb-1">
                                      <h1>
                                        {{ getDefault(item.contents).name }}
                                      </h1>
                                      <p class="mb-0">
                                        {{ $t('myquotes.id') }}:
                                        {{ item.product_id }}
                                      </p>
                                      <nl2br
                                        tag="p"
                                        className="mb-35"
                                        :text="
                                          getDefault(item.contents).description
                                        "
                                      />
                                    </b-col>
                                  </b-row>

                                  <b-row>
                                    <b-col cols="12">
                                      <b-row>
                                        <b-col cols="5">
                                          <p class="quotations-column-content">
                                            {{ $t('myquotes.ppu') }}
                                            <span
                                              >{{ item.currency.symbol }}
                                              {{ item.min_price }} -
                                              {{ item.currency.symbol
                                              }}{{ item.max_price }}</span
                                            >
                                            <span class="ton-color"
                                              >/ {{ item.unit.shortname }}</span
                                            >
                                          </p>
                                        </b-col>

                                        <b-col cols="3">
                                          <p class="quotations-column-content">
                                            {{ $t('myquotes.moq') }}
                                            <span>{{ numberFixer(item.moq)}} </span>
                                            <span class="ton-color">
                                              {{ item.unit.shortname }}</span
                                            >
                                          </p>
                                        </b-col>

                                        <b-col cols="4">
                                          <p class="quotations-column-content">
                                            {{ $t('myquotes.capacity') }}
                                            {{ numberFixer(item.stock) }}
                                            <span class="ton-color">
                                              {{ item.unit.shortname }}</span
                                            >
                                          </p>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-card-text>
                            <!--<b-card-text>{{ text }}</b-card-text> -->
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>

                    <!--  <b-overlay
                      :show="selectIndex.includes(index)"
                      rounded
                      opacity="0.9"
                      v-for="(item, index) in list"
                      :key="index"
                      spinner-small
                      spinner-variant="gray"
                      class="w-100"
                      z-index="9999"
                    >
                      <div class="item d-flex align-items-center">
                        <div class="col pn d-flex align-items-center">
                          <div>
                            <img
                              :src="
                                !isValidURL(item.images[0])
                                  ? item.images[0]
                                  : item.images[0] + '?size=50xauto'
                              "
                              alt=""
                            />
                          </div>
                          <div>
                            <h3 class="product-name onepar">
                              {{ getDefault(item.contents).name }}
                            </h3>
                            <span class="product-id onepar"
                              >Product ID: {{ item.product_id }}</span
                            >
                          </div>
                        </div>
                        <div
                          style="min-width: 310px; max-width: 310px"
                          class="col con"
                        >
                          <span class="product-desc"
                            >{{ getDefault(item.contents).description }}
                          </span>
                        </div>
                        <div
                          style="min-width: 154px; max-width: 154px"
                          class="col un"
                        >
                          <span class="product-unit">{{ item.unit.shortname }}</span>
                        </div>
                        <div
                          style="min-width: 254px; max-width: 254px"
                          class="col"
                        >
                          <span class="product-price"
                            >{{ item.currency.symbol }} {{ item.min_price }} -
                            {{ item.max_price }}</span
                          >
                        </div>
                        <div
                          style="min-width: 211px; max-width: 211px"
                          class="col d-flex justify-content-between"
                        >
                          <span class="product-price">{{ item.stock }}</span>
                          <b-dropdown
                            right
                            :ref="'dropdown' + index"
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                          >
                            <template #button-content class="p-0">
                              <v-icon :icon="['fas', 'ellipsis-v']"></v-icon>
                            </template>
                            <div class="py-2 px-3">
                              <b-form-checkbox
                                @change="itemStatusChange(index)"
                                v-model="item.active"
                                name="check-button"
                                switch
                              >
                                Active
                              </b-form-checkbox>
                            </div>
                            <b-dropdown-item
                              @click.prevent="openEditItem(index)"
                              class="ml-0"
                              href="#"
                              ><v-icon
                                :icon="['fac', 'edit']"
                                class="mr-3"
                              />Edit</b-dropdown-item
                            >
                          </b-dropdown>
                        </div>
                      </div>
                    </b-overlay> -->
                  </div>
                </div>
              </div>
            </b-tab>
            <template #tabs-end>
              <div class="listings__tabs__input-wrapper">
                <b-form-input
                  v-model="filters.search"
                  @keydown="searchOnKeyDown"
                  class="nav-item"
                  type="text"
                  placeholder="Search"
                />
               <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                </svg>

              </div>
            </template>
          </b-tabs>
        </keep-alive>
      </div>
    </div>
    <b-row class="gridview-pagination" v-if="!loading">
      <b-col cols="12">
        <b-pagination
          v-if="$_.size(list) > 0"
          class="mt-4"
          align="center"
          v-model="page"
          pills
          :total-rows="total"
          :per-page="limit"
          @change="onPageChange()"
        >
        </b-pagination>
      </b-col>
    </b-row>
    <product-edit-sidebar @updated="updateData" :item="selectedItem" />
  </div>
</template>

<script>
import emptyItem from '@/components/app/me/emptyItem';
import loading from '@/components/global/loading/index';
import getListingCount from '@/graphql/me/getProductCount.graphql';
import productEditSidebar from '@/components/app/profile/productEditSidebar';
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  name: 'my-listing',
  components: {
    emptyItem,
    loading,
    productEditSidebar,
  },
  data() {
    return {
      upadtedLoad: true,
      selectIndex: [],
      deleteIndex: [],
      checked: false,
      tabIndex: null,
      isEmpty: false,
      loading: true,
      page: 1,
      total: null,
      limit: 12,
      list: [],
      filters: {
        active: true,
        search: null,
      },
      selectedItem: {},
      alert: {
        status: false,
        type: null,
        message: null,
      },
      countActive: null,
      countPassive: null,
      searchOnKeyDown: null,
    };
  },
  watch: {
    async tabIndex(val, oldVal) {
      if (oldVal !== null) {
        this.cursors = [];
        this.currentPage = 1;
        this.list = [];
        this.cursor = null;
        if (val === 0) {
          this.filters.active = true;
        }
        if (val === 1) {
          this.filters.active = false;
        }
        await this.getListing();
      }
    },
    GET_ITEM_KEY() {
        this.getListingCount(true);
        this.getListingCount(false);
        this.getListing();
    }
  },
  methods: {
    isValidURL(string) {
      const res = string.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    },
    async updateData(val) {
      this.upadtedLoad = false;
      let index = this.list.findIndex(item => item.id === val.id);
      if (index !== -1) {
        this.$set(this.list, index, val);
        this.getListingCount(true);
        this.getListingCount(false);
        this.cursor = null;
        this.getListing();
        this.$nextTick(() => {
          this.upadtedLoad = true;
        });
      }
    },
    openEditItem(index) {
      this.selectedItem = this.list[index];
      this.$root.$emit('bv::toggle::collapse', 'productEditSidebar');
    },
    async itemStatusChange(index) {
      let x = this.list[index].active;

      let id = this.list[index].id;
      this.selectIndex.push(index);
      let dropref = 'dropdown' + index;

      let payload = {
        id: this.list[index].id,
        active: x,
      };
      try {
        let data = await this.$store.dispatch('inquiries/updateProduct', {
          vm: this,
          payload,
        });
        this.$refs[dropref][0].hide(true);
        this.selectIndex = [];
        this.list = this.list.filter(item => item.id !== id);
      } catch (e) {
        console.log(e);
      }
    },
    async getListingCount(active = true) {
      try {
        this.alert.status = false;
        this.loading = true;
        let data = await this.$apollo.query(getListingCount, {
          filters: {
            active,
          },
        });
        let count = data('**.count');

        if (active == true) {
          this.countActive = count;
        } else {
          this.countPassive = count;
        }

        /*if (count === 0) {
          return (this.isEmpty = true);
        } */
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
        this.alert.status = true;
        this.alert.type = 'system';
        this.alert.message = msg;
      } finally {
        this.loading = false;
      }
    },
    async getListing() {
      try {
        this.loading = true;
        let response = await this.$store.dispatch('inquiries/getListings', {
          vm: this,
          payload: {
            limit: this.limit,
            filters: this.filters,
            page: this.page,
          },
        });
        this.cursor = response.next_cursor;
        this.total = response.total || 0;
        this.$set(this, 'list', response.list);
      } catch (e) {
        console.log(e);
      } finally {
          this.loading = false;
      }
    },
    onPageChange() {
      this.$nextTick()
        .then(() => this.getListing())
        .then(() => this.appScrollTop());
    },
  },

  computed: {
      ...mapGetters(['GET_ITEM_KEY']),
  },

  async created() {
    this.searchOnKeyDown = _.debounce(() => this.getListing(), 1000);
    if (this.tabIndex === 0) {
      this.filters.active = true;
    }
    if (this.tabIndex === 1) {
      this.filters.active = false;
    }
    await this.getListingCount(true);
    await this.getListingCount(false);
    await this.getListing();
  },
};
</script>
