<template>
  <div>
    <b-sidebar
      @shown="shownSidebar"
      @hidden="hiddenSidebar"
      id="productEditSidebar"
      aria-labelledby="sidebar-no-header-title"
      body-class="bg-white"
      shadow
      style="height:130vh !important!; zoom:1.4; "
      width="635px"
      :backdrop="true"
      :right="true"
      z-index="9998"
      
    >
      <template #header="{ hide }">
        <div>
          <div class="sidebar-header d-flex align-items-center">
            <span class="icon">
              <v-icon :icon="['fac', 'edit']" />
            </span>
            <h4 id="sidebar-no-header-title" class="p-0 m-0">
              {{$t("myquotes.edit-quote")}}
            </h4>
          </div>
        </div>
      </template>
      <template #default>
        <div
          v-if="loading"
          class="d-flex flex-column h-100 justify-content-center align-items-center"
        >
          <loading class="my-auto" :loading="loading" />
        </div>

        <div v-if="!loading" class="py-5 px-4">
          <validation-observer
            ref="editItem"
            v-slot="{ touched, invalid, changed }"
          >
            <div class="form-group mb-34">
              <div class="form-group mb-34">
                <validation-provider
                  name="Industry"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >

                 <div class="form-group mb-5">
                    <b-form-radio-group
                        v-model="form.active"
                        :options="statusOptions"
                        class="mr-3"
                        name="active"
                    >
                    </b-form-radio-group>
                </div>
                  <label for="#">
                     {{$t("myquotes.label-0")}}
                    <span class="required">*</span>
                  </label>
                  <multi-select
                    :class="{
                      'is-invalid':
                        !getValidationState(validationContext) &&
                        validationContext.errors[0],
                    }"
                    name="Industry"
                    id="industry"
                    @close="closeSelect"
                    :openDirection="'bottom'"
                    :searchable="false"
                    v-model="form.industry"
                    :options="industrys"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title">
                          {{ props.option.name }}
                        </span>
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.name }}
                        </span>
                      </div>
                    </template>
                  </multi-select>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </div>
            </div>
            <validation-provider
              name="Product Name"
              :rules="{ itemName: true }"
              v-slot="validationContext"
            >
              <div class="form-group mb-34">
                <label for>
                  {{$t("myquotes.label-1")}}
                  <small style="color: c5c5c5" class="ml-2">
                    {{$t("myquotes.label-desc")}}</small
                  >
                </label>
                <input spellcheck="true"
                  v-model="form.name"
                  :class="{
                    'is-invalid':
                        (
                            !getValidationState(validationContext) 
                            &&
                            validationContext.errors[0]
                        )
                  }"
                  type="text"
                  class="form-control"
                  placeholder="Type in the name of the product you are looking for"
                />
                <small v-if="validationContext.errors[0]" class="text-danger">
                    {{ validationContext.errors[0] }}
                </small>
              </div>
            </validation-provider>
            <validation-provider
              name="Product ID"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <div class="form-group mb-34">
                <label for>
                  {{$t("myquotes.label-2")}}
                  <span class="required">*</span>
                </label>
                <input spellcheck="true"
                  v-model="form.id"
                  :class="{
                    'is-invalid':
                      !getValidationState(validationContext) &&
                      validationContext.errors[0],
                  }"
                  type="text"
                  class="form-control"
                  placeholder="Type in the name of the product you are looking for"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </div>
            </validation-provider>
            <validation-provider
              name="Product Description"
              :rules="{ itemDescription: true }"
              v-slot="validationContext"
            >
              <div class="form-group mb-34">
                <label for>{{$t("myquotes.label-3")}}</label>
                <textarea spellcheck="true"
                  v-model="form.desc"
                  :class="{
                    'is-invalid':
                        (
                            !getValidationState(validationContext) 
                            &&
                            validationContext.errors[0]
                        )
                  }"
                  type="text"
                  rows="4"
                  class="form-control"
                ></textarea>
                 <div class="counter">
                <small v-if="validationContext.errors[0]" class="text-danger">
                    {{ validationContext.errors[0] }}
                </small>
                <div class="number-back-counter">
                  {{ counter }} / 1200
                </div>
                </div>
              </div>
            </validation-provider>
            <label for>
              {{$t("myquotes.label-4")}}
              <span class="required">*</span>
            </label>
            <div class="dropzone-content mb-34">
              <vue-dropzone
                @vdropzone-removed-file="deleteImage"
                @vdropzone-files-added="fileUpload"
                @vdropzone-thumbnail="getThumbnail"
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
              >
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    {{$t("myquotes.addimg-title")}}
                  </h3>
                  <small class="text-muted d-block">{{$t("myquotes.or")}}</small>
                  <button @click.prevent class="btn btn-primary">
                   {{$t("buttons.add")}}
                  </button>
                  <p>JPG,PNG 5MB {{$t("myquotes.max")}}</p>
                </div>
              </vue-dropzone>
              <div
                class="img-preview-content mt-3 d-flex justify-content-between"
              >
                <div v-for="(b, i) in 5" :key="i" class="item">
                  <div
                    @click.prevent="deleteThumbnail(i, productImages[i].type)"
                    v-if="productImages[i]"
                    class="clearBtn"
                  >
                    X
                  </div>

                  <img
                    :src="
                      !productImages[i]
                        ? noImage
                        : /^data/i.test(productImages[i].src)
                            ? productImages[i].src 
                            : CDN_IMAGE_PREFIX + productImages[i].src
                    "
                    alt
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group mb-34">
                  <validation-provider
                    name="units"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for="#"> {{$t("myquotes.label-5")}} </label>
                    <multi-select
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      name="units"
                      id="units"
                      @close="closeSelect"
                      :openDirection="'bottom'"
                      :searchable="false"
                      v-model="form.unit"
                      :options="units"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">
                            {{ props.option.name }}
                          </span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">
                            {{ props.option.name }}
                          </span>
                        </div>
                      </template>
                    </multi-select>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="form-group row mb-34 d-flex justify-content-between">
              <div class="col">
                <label for> {{$t("myquotes.label-6")}} </label>
                <multi-select
                  :placeholder="'Select currency'"
                  track-by="name"
                  id="relatedIndustry"
                  label="name"
                  :clearOnSelect="false"
                  :closeOnSelect="true"
                  :allowEmpty="false"
                  :showLabels="false"
                  v-model="selectedCurrency"
                  :options="currencies"
                >
                </multi-select>
              </div>
              <div class="col">
                <validation-provider
                  name="Minimum Price"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <label for> {{$t("myquotes.label-7")}} </label>
                  <currency-input
                    :currency="selectedCurrency.code"
                    v-model="form.min_price"
                    :class="{
                      'is-invalid':
                        !getValidationState(validationContext) &&
                        validationContext.errors[0],
                    }"
                    class="form-control"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </div>
              <div class="col">
                <validation-provider
                  name="Maximum Price"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <label for> {{$t("myquotes.label-8")}} </label>
                  <currency-input
                    :currency="selectedCurrency.code"
                    v-model="form.max_price"
                    :class="{
                      'is-invalid':
                        !getValidationState(validationContext) &&
                        validationContext.errors[0],
                    }"
                    class="form-control"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row mb-34 d-flex justify-content-between">
              <div class="col">
                <div class="form-group mb-34 d-flex justify-content-between">
                  <div class="w-100">
                    <validation-provider
                      name="Stock"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <label for
                        >{{$t("myquotes.label-9")}}
                        <span style="color: #989898"
                          >( {{ form.unit.shortname }} )</span
                        ></label
                      >
                      <input spellcheck="true"
                        v-model="form.stock"
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        type="number"
                        class="form-control"
                      />
                      <small class="text-danger">{{
                        validationContext.errors[0]
                      }}</small>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="form-group mb-34 d-flex justify-content-between">
                  <div class="w-100">
                    <validation-provider
                      name="moq"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <label for
                        >{{$t("myquotes.label-10")}}
                        <span style="color: #989898">
                          ( {{ form.unit.shortname }} )</span
                        ></label
                      >
                      <input spellcheck="true"
                        v-model="form.moq"
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        type="number"
                        class="form-control"
                      />
                      <small class="text-danger">{{
                        validationContext.errors[0]
                      }}</small>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </template>
      <template class="bg-white" #footer="{ hide }">
        <div
          class="d-flex bg-white shadow-lg justify-content-between align-items-center px-3 py-3"
        >
          <a @click.prevent="hide" href="#">{{$t("buttons.exit")}}</a>
          <button @click="updateItem" class="btn btn-primary btn-sm">
            {{$t("buttons.save")}}
          </button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import step4 from "@/components/app/matching/component/addItemSteps/step4";
import baseImage from "@/assets/img/product-add-no-image.png";
import _ from 'lodash';
export default {
  name: "productEditSidebar",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    step4,
  },
  data() {
    return {
      inputCurrency: null,
      noImage: baseImage,
      productImages: [],
      dropzoneOptions: {
        createImageThumbnails: true,
        addRemoveLinks: true,
        autoProcessQueue: false,
        url: "https://httpbin.org/post",
        thumbnailWidth: null,
        thumbnailHeight: null,
        acceptedFiles: "image/*",
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
      },
      currencies: [],
      loading: true,
      industrys: [],
      selectedCurrency: null,
      selectedIndustry: null,
      units: [],
      selectedUnit: null,
      selectItem: {},
      statusOptions: [
        { text: this.$t('buttons.active'), value: true },
        { text: this.$t('buttons.passive'), value: false }
      ],
      form: {
        id: null,
        deletedImg: [],
        name: null,
        desc: null,
        demand: null,
        images: [],
        active: null
      },
    };
  },
  computed: {
    counter() {
        return _.size(this.form.desc);
    }
  },
  methods: {
    deleteThumbnail(index, type) {
      if (type === "added") {
        this.databaseImgDelete(index);
      }
      
      this.productImages.splice(index, 1);
      this.productImages = [...this.productImages];
    },
    databaseImgDelete(index) {
      let image = this.productImages[index].src;
      let code = image.replace(this.CDN_IMAGE_PREFIX, "");

      this.form.deletedImg.push(code);
    },
    setInputCurrency() {
      let handler = this.currencies.find(
        (item) => item.id === this.selectedCurrency.id
      );
      this.inputCurrency = handler.code;
    },
    closeSelect(value, id) {
      console.log(id);
      if (id == "industry") {
        if (!this.form.industry) {
          let msg = this.$t("validations.required", { _field_: id });
          return this.$refs.observer.setErrors({
            Industry: [msg],
          });
        }
      }
      if (id == "years") {
        if (!this.form.years) {
          let msg = this.$t("validations.required", { _field_: id });
          return this.$refs.observer.setErrors({
            years: [msg],
          });
        }
      }
      if (id == "positions") {
        if (!this.form.position) {
          let msg = this.$t("validations.required", { _field_: id });
          return this.$refs.observer.setErrors({
            Positions: [msg],
          });
        }
      }
    },
    deleteImage($event) {
      console.log($event);
    },
    getThumbnail(file, dataUrl, $event) {
      if (this.productImages.length === 5) {
        return;
      }
      this.form.images.push(file);
      this.productImages.push({
        src: dataUrl,
        type: "new",
      });
    },
    fileUpload(file) {
      if (this.productImages.length === 5) {
        this.$bvToast.toast(this.$t("Toaster.img-add-up"), {
           title: this.$t("Toaster.error-title"),
          autoHideDelay: 1350,
        });
        return false;
      }
    },
    selectCurrency(selectedOption) {},
    async getCurrencies() {
      try {
        let response = await this.$store.dispatch("inquiries/getCurrencies", {
          vm: this,
        });
        this.currencies = response;
        console.log(response);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchIndustry() {
      try {
        let par = {
          type: "PRODUCT_AND_INQUIRY",
        };
        let response = await this.$store.dispatch("inquiries/getIndustries", {
          vm: this,
          payload: par,
        });
        this.industrys = response;
      } catch (e) {
        console.log(e);
      }
    },
    async getUnits() {
      try {
        let response = await this.$store.dispatch("inquiries/getUnits", {
          vm: this,
        });
        this.units = response;
      } catch (e) {
        console.log(e);
      }
    },
    fetchData() {
      this.selectItem = this.item;

      //industry
      this.form.industry = this.item.industry;
      // unit
      this.form.unit = this.item.unit;
      // name, description and demand
      let content = this.item.contents;
      this.form.name = this.getDefault(content).name;
      this.form.desc = this.getDefault(content).description;
      this.form.id = this.item.product_id;
      this.selectedCurrency = this.item.currency;
      this.form.min_price = this.item.min_price;
      this.form.max_price = this.item.max_price;
      this.form.moq = this.item.moq;
      this.form.demand = this.item.demand;
      this.form.stock = this.item.stock;
      this.form.active = this.item.active;
      let x = this.item.images;
      for (let i in x) {
        this.productImages.push({
          src: `${x[i]}`,
          type: "added",
        });
      }
    },
    async shownSidebar() {
      await this.getCurrencies();

      await this.fetchIndustry();
      await this.getUnits();
      this.loading = false;
      this.fetchData();
      this.setInputCurrency();
      console.log(this.selectItem);
    },
    async updateItem() {
      this.$refs.editItem.validate().then(async (success) => {
        if (!success) {
          return;
        }

        if (this.form.images.length === 0 && this.productImages.length === 0) {
          return this.$bvToast.toast(this.$t("Toaster.img-min-upload"),
            {
              title: this.$t("Toaster.error-title"),
              variant: "danger",
             autoHideDelay: 1350,
            }
          );
        }
        if (this.form.max_price <= this.form.min_price) {
          return console.log("Hoop");
        }
        this.loading = true;

        try {
          let payload = {
            id: parseInt(this.item.id),
            product_id: this.form.id,
            industry_id: parseInt(this.form.industry.id),
            name: this.form.name,
            unit_id: parseInt(this.form.unit.id),
            currency_id: parseInt(this.selectedCurrency.id),
            min_price: this.form.min_price,
            max_price: this.form.max_price,
            moq: parseInt(this.form.moq),
            stock: parseInt(this.form.stock),
            description: this.form.desc,
            images: this.form.images,
            delete_images: this.form.deletedImg,
            active: this.form.active
          };
          let response = await this.$store.dispatch("inquiries/updateProduct", {
            vm: this,
            payload,
          });
          let updateData = {
            id: this.item.id,
            industry: this.selectedIndustry,
            product_id: this.form.id,
            stock: parseInt(this.form.stock),
            moq: parseInt(this.form.moq),
            active: this.item.active,
            unit: this.form.unit,
            currency: this.selectedCurrency,
            images: [this.productImages[0].src],
            min_price: this.form.min_price,
            max_price: this.form.max_price,
            active: this.form.active,
            contents: [
              {
                default: true,
                name: this.form.name,
                description: this.form.desc,
              },
            ],
          };
          this.$emit("updated", updateData);
          this.$root.$emit("bv::toggle::collapse", "productEditSidebar");
          return this.$bvToast.toast(this.$t("Toaster.updated-request"), {
             title: this.$t("Toaster.success-title"),
            variant: "success",
            autoHideDelay: 1350,
          });
        } catch (e) {
          
          console.log(msg);
            return this.$bvToast.toast(this.$t(e.message), {
             title: this.$t("Toaster.error-title"),
            variant: "danger",
            autoHideDelay: 1350,
          });
        } finally {
          this.loading = false;
        }
      });
    },
    hiddenSidebar() {
      this.form = {
        name: null,
        desc: null,
        demand: null,
        images: [],
        active: null,
        deletedImg: [],
      };
      this.productImages = [];
      this.selectItem = {};
      this.loading = true;
      this.industires = [];
      this.selectedIndustry = null;
      this.units = [];
      this.selectUnits = null;
    },
  },
  created() {
  }
};
</script>
